var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.messageToEdit
    ? _c(
        "AGDialog",
        {
          attrs: {
            width: "428px",
            title: "Condição da mensagem",
            persistent: "",
          },
          on: {
            tertiaryButtonClick: _vm.close,
            primaryButtonClick: function ($event) {
              return _vm.editMessage()
            },
          },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-select", {
                    attrs: {
                      id: "inputSituation",
                      items: _vm.situations,
                      "item-text": "text",
                      "item-value": (item) => item.value,
                      label: "Situação",
                      dense: "",
                      outlined: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.messageToEdit.situation,
                      callback: function ($$v) {
                        _vm.$set(_vm.messageToEdit, "situation", $$v)
                      },
                      expression: "messageToEdit.situation",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("v-select", {
                    attrs: {
                      id: "inputUrgent",
                      items: _vm.urgent,
                      "item-text": "text",
                      "item-value": (item) => item.value,
                      label: "Urgente",
                      dense: "",
                      outlined: "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.messageToEdit.urgent,
                      callback: function ($$v) {
                        _vm.$set(_vm.messageToEdit, "urgent", $$v)
                      },
                      expression: "messageToEdit.urgent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }