<template>
  <AGDialog
    v-if="messageToEdit"
    v-model="show"
    width="428px"
    title="Condição da mensagem"
    persistent
    @tertiaryButtonClick="close"
    @primaryButtonClick="editMessage()"
  >
    <v-row>
      <v-col>
        <v-select
          id="inputSituation"
          v-model="messageToEdit.situation"
          :items="situations"
          item-text="text"
          :item-value="(item) => item.value"
          label="Situação"
          dense
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-select
          id="inputUrgent"
          v-model="messageToEdit.urgent"
          :items="urgent"
          item-text="text"
          :item-value="(item) => item.value"
          label="Urgente"
          dense
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
  </AGDialog>
</template>

<script>
import IntegraContadorService from '@/services/integraContadorService.js';

import { eventBus } from '../../../../main';
import Enum from './../ecacMessages.json';

export default {
  name: 'ModalConditionMessage',
  components: {
    AGDialog: () => import('../../commons/AGDialog.vue'),
  },

  props: ['message'],

  data() {
    return {
      show: false,
      titulo: 'Condição da mensagem',
      situations: [
        { text: 'Resolvido', value: 'RESOLVIDO' },
        { text: 'Em Andamento', value: 'EM_ANDAMENTO' },
        { text: 'Verificar', value: 'VERIFICAR' },
      ],

      relevance: Enum.relevance,
      urgent: [
        { text: 'Sim', value: true },
        { text: 'Não', value: false },
      ],

      menu: false,
    };
  },

  computed: {
    messageToEdit() {
      return this.message;
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.$on('edit', this.edit);
      this.$on('close', this.close);
    },

    edit() {
      this.show = true;
    },

    close() {
      this.$emit('refresh');
      this.show = false;
    },

    async editMessage() {
      let response = await IntegraContadorService.messages.update(this.message);
      this.close();
      if (response) {
        this.showToast('Condições da mensagem alterada com sucesso!', 'success', 78);
      } else {
        this.showToast('Erro ao alterar a mensagem', 'warning', 78);
      }
    },

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },
  },
};
</script>

<style>
.v-application .v-select__selection {
  color: var(--v-primary-base);
}
</style>
